
import { Vue, Options } from 'vue-class-component';
import { i18n } from '@/i18n/i18n';
import {
  DomainUserService,
  OrganisationRoleService,
  OrganisationUserService,
  ExternalIdentifierTypeService
} from '@/services/api';
import {
  AvatarUpload,
  BaseSwitch,
  BaseTextInput,
  ButtonLink,
  BaseButton,
  BaseIcon,
  BaseModal,
  BaseCard,
  CardHeader,
  DataTable,
  RolesSelector,
  PhoneNumberInput,
  BasePopover,
  PopoverButton,
  SpecialityButton
} from '@/lib/components';
import { Domain, Organisation, Role, User } from '@/models';
import axios, { CancelTokenSource, CancelToken } from 'axios';
import { isValidEmail } from '@/helpers/strings.helper';
import { isGMCCodeValid } from '@/lib/helpers/gmc-code.helper';
import { ExternalIdentifierType } from '@/models/external-identifier-type.model';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    usersPath: {
      type: String,
      required: true
    },
    organisation: {
      type: Object,
      default: null
    }
  },
  components: {
    CardHeader,
    BaseCard,
    BaseSwitch,
    BaseTextInput,
    BaseButton,
    ButtonLink,
    BaseIcon,
    AvatarUpload,
    PhoneNumberInput,
    BaseModal,
    DataTable,
    RolesSelector,
    BasePopover,
    PopoverButton,
    SpecialityButton
  }
})
export default class NewUserPage extends Vue {
  organisation!: Organisation | Domain | null;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  usersPath!: string;
  givenName = '';
  familyName = '';
  phone = '';
  email = '';
  gmcCodeType: ExternalIdentifierType | null = null;
  gmcCode = '';
  roles: Role[] = [];
  selectedRoles: number[] = [];
  currentRoles: number[] = [];
  showRoleModal = false;
  loading = false;
  saving = false;
  request: CancelTokenSource | null = null;
  errors: { [key: string]: Array<string> } = {};
  externalIdentifierTypesService = new ExternalIdentifierTypeService();
  avatarId?: string = '';
  avatarLoading = false;
  locale: string = i18n.global.locale.value;

  get orgRoleService(): OrganisationRoleService {
    return new OrganisationRoleService(this.organisationId);
  }

  get usersService() {
    return this.organisationType === 'organisations'
      ? new OrganisationUserService(this.organisationId)
      : new DomainUserService(this.organisationId);
  }

  get roleAssignments(): Role[] {
    return this.roles.filter((role) => this.currentRoles.includes(role.id));
  }

  get organisationType(): string {
    return this.organisation?.type || '';
  }

  get organisationId(): string {
    return this.organisation?.id ?? '';
  }

  get showRoles(): boolean {
    return this.organisationType === 'organisations' && (this.sessionStore.permissions as string[]).includes('role:read');
  }

  get currentUser(): User | undefined {
    return this.sessionStore.currentUser;
  }

  beforeCreate() {
    this.locale = (this.currentUser && this.currentUser.locale) || i18n.global.locale.value;
  }

  created() {
    this.fetchData();
    this.$watch('organisationId', async () => {
      try {
        await this.fetchRoles(this.request?.token);
      } catch (e) {
        if (!axios.isCancel(e)) {
          await this.notificationStore.addErrorNotification({
            title: this.$t('platform.role.fetch-error')
          });
        }
      }
    });
  }

  mounted() {
    if (this.$route.query.email && typeof this.$route.query.email === 'string') {
      this.email = this.$route.query.email;
    }
    if (this.$route.query.roles && typeof this.$route.query.roles === 'string') {
      this.currentRoles = this.$route.query.roles.split(',').map((id) => +id);
    }
  }

  beforeUnmount() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  checkForm(): boolean {
    this.errors = {};
    if (!this.givenName.length) {
      this.errors = {
        ...this.errors,
        given_name: [this.$t('platform.error.field-required') as string]
      };
    }
    if (!this.familyName.length) {
      this.errors = {
        ...this.errors,
        family_name: [this.$t('platform.error.field-required') as string]
      };
    }
    if (!isValidEmail(this.email)) {
      this.errors = {
        ...this.errors,
        email: [this.$t('platform.error.email-error') as string]
      };
    }
    if (this.gmcCode.length && !isGMCCodeValid(this.gmcCode)) {
      this.errors = {
        ...this.errors,
        gmcCode: [this.$t('custom.uhb.user.gmc-code-error') as string]
      };
    }
    if (this.phone.length && this.$refs.phoneInput && !(this.$refs.phoneInput as PhoneNumberInput).isValid) {
      this.errors = {
        ...this.errors,
        phone: [this.$t('platform.error.phone-error') as string]
      };
    }
    return Object.keys(this.errors).length === 0;
  }

  validate(key: string) {
    if (this.errors[key] && this.errors[key].length) {
      this.checkForm();
    }
  }

  setRoleAssignModalVisibility(visible: boolean) {
    this.selectedRoles = this.currentRoles;
    this.showRoleModal = visible;
  }

  deleteRoleAssignment(id: number) {
    this.currentRoles = this.currentRoles.filter((role) => role !== id);
  }

  createRoleAssignments() {
    this.currentRoles = this.selectedRoles;
    this.setRoleAssignModalVisibility(false);
  }

  async fetchData() {
    this.request = axios.CancelToken.source();
    this.loading = true;
    const [roles, gmcCodeType] = await Promise.allSettled([
      this.fetchRoles(this.request.token),
      this.fetchGmcCodeType(this.request.token)
    ]);
    this.request = null;
    this.loading = false;
    if (gmcCodeType.status === 'fulfilled' && gmcCodeType.value) {
      this.gmcCodeType = gmcCodeType.value;
    }
    if (roles.status === 'fulfilled') {
      this.roles = roles.value;
    } else if (!axios.isCancel(roles.reason)) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.fetch-error')
      });
    }
  }

  async fetchRoles(cancelToken?: CancelToken): Promise<Array<Role>> {
    if (!this.showRoles) {
      return [];
    }

    return (await this.orgRoleService.index({ cancelToken })).data;
  }

  async fetchGmcCodeType(cancelToken: CancelToken) {
    return (
      await this.externalIdentifierTypesService.index({
        params: {
          'filter[code]': 'GMC'
        },
        cancelToken
      })
    ).data[0];
  }

  async createUser() {
    if (this.checkForm()) {
      try {
        this.progressStore.startProgress();
        this.saving = true;
        const response = await this.usersService.create({
          given_name: this.givenName,
          family_name: this.familyName,
          email: this.email,
          phone: this.phone,
          ...(this.organisationType === 'organisations'
            ? { organisational_unit_id: this.organisationId }
            : { domain_id: this.organisationId }),
          avatar_id: this.avatarId,
          locale: this.locale,
          roles: this.currentRoles,
          ...(this.gmcCodeType
            ? {
              external_identifiers: [
                {
                  value: this.gmcCode,
                  external_identifier_type_id: this.gmcCodeType?.id
                }
              ]
            }
            : {})
        });

        this.progressStore.finishProgress();
        this.notificationStore.addSuccessNotification({
          title: this.$t('platform.user.create-success')
        });
        await this.$router.push(`${this.usersPath}/${response.data.id}`);
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.user.create-error')
        });
      } finally {
        this.saving = false;
      }
    }
  }

  handleAvatarLoading(value: boolean) {
    this.avatarLoading = value;
  }

  async back() {
    await this.$router.push(this.usersPath);
  }
}
